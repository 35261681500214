<template>
    <div id="editSalesHours-container">
      <CCard id="editSalesHours-shopSelectionCard" class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol id="editSalesHours-shopIconColumn" sm="1" col="2">
              <img id="editSalesHours-shopIcon" src="/img/icon/store.png" style="width: 32px; height:32px;" />
            </CCol>
            <CCol id="editSalesHours-shopDropdownColumn" sm="9" col="8">
              <select id="editSalesHours-shopDropdown" class="custom-select text-black" v-model="shop" disabled>
                <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                  {{ shop.shopName }} - {{ shop.branchName }}
                </option>
              </select>
            </CCol>
            <div id="editSalesHours-backButtonColumn" class="col-md-2 col-sm-2 col-2 text-right" v-if="loadingButton == true">
              <CButton id="editSalesHours-backButton" block color="light" @click="goback()">
                <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
              </CButton>
            </div>
            <div id="editSalesHours-loadingButtonColumn" class="col-md-2 col-sm-2 text-right" v-else>
              <CButton id="editSalesHours-loadingButton" block color="success" disabled>
                <CSpinner id="editSalesHours-loadingSpinner" color="white" size="sm" />{{ $t('loading') }}
              </CButton>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
  
      <div id="editSalesHours-permissionsWrapper" v-if="isPermission">
        <CCard id="editSalesHours-card" class="shadow-sm">
          <CCardBody style="padding: 1.55rem">
            <div id="editSalesHours-headerRow" class="row mb-3">
              <div id="editSalesHours-headerTitle" class="col-md-10 col-sm-9 col-8 d-inline-flex align-items-center">
                <h2>{{ $t("editsalesHoursManagement") }}</h2>
              </div>
              <div id="editSalesHours-saveButtonColumn" class="col-md-2 col-sm-9 col-4">
                <CButton id="editSalesHours-saveButton" block color="success" @click="saveSchedule">
                  {{ $t("save") }}
                </CButton>
              </div>
            </div>
            <CRow>
              <CCol id="editSalesHours-salesHoursLabel" class="col-form-label" style="margin-top: 2%;" lg="2">
                <h6>{{ $t('txtsalesHours') }}</h6>
              </CCol>
              <CCol id="editSalesHours-salesHoursInputColumn" style="margin-top: 2%;" lg="10">
                <CInput
                  id="editSalesHours-salesHoursInput"
                  v-if="validateName"
                  v-model="name"
                  :placeholder="$t('fillSaleName')"
                  :is-valid="validator"
                  :invalid-feedback="$t('fillSaleName')"
                />
                <CInput
                  id="editSalesHours-salesHoursInputAlt"
                  v-else
                  v-model="name"
                  :placeholder="$t('fillSaleName')"
                />
              </CCol>
            </CRow>
            <hr />
            <h5 class="mt-4">{{ $t('salesHoursSetting') }}</h5>
            <CAlert
              id="editSalesHours-alert"
              color="info"
              class="d-inline-block col-12 mt-2"
              style="font-size: 14px; padding: 8px 12px"
            >
              <i class="fi fi-rr-info mr-1"></i> {{ $t("txtSalesHoursSetting") }}
            </CAlert>
            <div id="editSalesHours-scheduleContainer" class="schedule-container">
              <div
                v-for="(day, dayIndex) in days"
                :key="dayIndex"
                :id="'editSalesHours-daySchedule-' + dayIndex"
                class="day-schedule"
              >
                <CRow id="editSalesHours-dayRow" class="justify-content-between m-1">
                  <CCol id="editSalesHours-dayCheckboxColumn" col="3" sm="2" md="2" lg="3">
                    <div id="editSalesHours-dayHeader" class="day-header">
                      <input id="editSalesHours-dayCheckbox" type="checkbox" v-model="day.enabled" />
                      <span>{{ $t(day.name) }}</span>
                    </div>
                  </CCol>
                  <CCol id="editSalesHours-timeSlotColumn" col="9" md="8" lg="9" class="text-right">
                    <CRow
                      v-for="(slot, slotIndex) in day.slots"
                      :key="slotIndex"
                      class="justify-content-end m-2"
                    >
                      <CCol id="editSalesHours-startTimeColumn" col sm="4" md="4" lg="3" class="text-right">
                        <vue-timepicker
                          id="editSalesHours-startTimePicker"
                          input-class="form-control"
                          v-model="slot.start"
                          :disabled="!day.enabled"
                          :min-time="slot.minTime"
                          :max-time="slot.maxTime"
                        />
                      </CCol>
                      <CCol id="editSalesHours-separatorColumn" col="1" md="1" lg="1" class="text-center">
                        <span>-</span>
                      </CCol>
                      <CCol id="editSalesHours-endTimeColumn" col="5" md="4" lg="3" class="text-left">
                        <vue-timepicker
                          id="editSalesHours-endTimePicker"
                          input-class="form-control"
                          v-model="slot.end"
                          :disabled="!day.enabled"
                          :min-time="slot.minTime"
                          :max-time="slot.maxTime"
                        />
                      </CCol>
                      <CCol id="editSalesHours-actionsColumn" col="1" md="1" lg="1" class="text-right">
                        <button
                          id="editSalesHours-addSlotButton"
                          class="add-btn"
                          @click="addSlot(dayIndex)"
                          :disabled="!day.enabled || day.slots.length >= maxSlots"
                          v-if="slotIndex === 0"
                        >
                          <img style="width: 25px;" src="/img/flat-color-icons--plus.png" />
                        </button>
                        <button
                          id="editSalesHours-removeSlotButton"
                          class="delete-btn"
                          @click="removeSlot(dayIndex, slotIndex)"
                          :disabled="!day.enabled"
                          v-else
                        >
                          <img src="/img/product/delete32.png" style="width: 30px;" />
                        </button>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
      <div id="editSalesHours-noPermission" v-else>
        <p>{{ $t("noPermission") }}</p>
      </div>
    </div>
  </template>
  

<script>
import { mapGetters } from 'vuex'
import VueTimepicker from "vue2-timepicker";
import pos from '@/services/local'

export default {
    components: {
        VueTimepicker,
    },
    data() {
        return {
            loadingButton: false,
            openingHour: {},
            validateName: '',
            name : '',
            maxSlots: 3, // Max number of slots allowed per day
            days: [
                { name: 'monday', enabled: true, slots: [] },
                { name: 'tuesday', enabled: true, slots: [] },
                { name: 'wednesday', enabled: true, slots: [] },
                { name: 'thursday', enabled: true, slots: [] },
                { name: 'friday', enabled: true, slots: [] },
                { name: 'saturday', enabled: true, slots: [] },
                { name: 'sunday', enabled: true, slots: [] },
            ],
        };
    },
    watch: {
        'slot.start'(newStart) {
            if (newStart >= this.slot.end) {
                this.slot.end = ""; // Reset the end time to ensure it's valid
            }
        },
    },
    created() {
        this.getManageSaleHours();
    },
    computed: {
        ...mapGetters(['shops', 'users', 'isOwner']),
        isPermission() {
            return true;
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
            },
        shop() {
            return this.shops.find((i) => i.objectId === this.shopObjectId)
        },
    },
    methods: {
        handlePopup() {
            if (this.name == '') {
                this.validateName = 'กรุณากรอกชื่อตัวเลือก'
            }  else {
                this.validateName = ''
                this.savedata()
            }
        },
        getManageSaleHours(){
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`
            let params = {
                shopObjectId: shopObjectId,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/shop/ordershopsetting",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                const dayMapping = {
                    'monday': "mon",
                    'tuesday': "tue",
                    'wednesday': "wed",
                    'thursday': "thu",
                    'friday': "fri",
                    'saturday': "sat",
                    'sunday': "sun",
                };
                let data = []
                let openingHour = res.data.data.grab.openingHour
                this.openingHour = openingHour
                this.days.forEach((day) => {
                    
                    const dayKey = dayMapping[day.name];

                    if (openingHour[dayKey]) {
                        day.enabled = true; // Enable the day if slots exist in `openingHour`

                        day.slots = openingHour[dayKey].map((slot) => ({
                            name: slot.name,
                            start: slot.startTime,
                            end: slot.endTime,
                            minTime: openingHour[dayKey][0].startTime,
                            maxTime: openingHour[dayKey][0].endTime,
                        }));

                        // Add all slots of the day to the data array
                        data = data.concat(day.slots);
                    }
                });

                if(res.status ==200){
                    let isGrabOrderEnabled  = res.data.data.isGrabOrderEnabled
                    let openingHour = res.data.data.grab.openingHour
                    if(isGrabOrderEnabled!== undefined && isGrabOrderEnabled !== null && isGrabOrderEnabled!==''){
                        this.isGrabOrderEnabled = isGrabOrderEnabled
                    }else{
                        this.isGrabOrderEnabled = false
                    }

                    if(openingHour !== undefined && openingHour !== null && openingHour !== ''){
                        this.openingHour = openingHour                        
                    }else{
                        this.openingHour = {}
                    }

                }else{
                    this.isGrabOrderEnabled = false
                }
                this.loadingButton = true
                
            }).catch((error) => {
                    console.log(error);
            });
        },
        validator(val) {
            return val ? val.length >= 1 : false
        },
        goback(){
            this.$router.push({
                name: 'MangeSalesHours',
            });
        },
        addSlot(dayIndex) {
            if (this.days[dayIndex].slots.length < this.maxSlots) {
                const minTime = this.days[dayIndex].slots[0]?.minTime || "08:30";
                const maxTime = this.days[dayIndex].slots[0]?.maxTime || "21:30";
                this.days[dayIndex].slots.push({ start: minTime, end: maxTime ,minTime, maxTime});
            } else {
                alert(this.$t("maxSlotsReached")); // Show alert if max slots are reached
            }
        },
        removeSlot(dayIndex, slotIndex) {
            this.days[dayIndex].slots.splice(slotIndex, 1);
        },
        validateSchedule() {
            return this.days.every((day) =>
                day.slots.every(
                    (slot) =>
                        slot.start !== "" &&
                        slot.end !== "" &&
                        slot.start < slot.end
                )
            );
        },
        saveSchedule() {
            const isValid = this.validateSchedule();
            if (!isValid) {
                alert(this.$t("invalidSchedule"));
                return;
            }
            alert(this.$t("scheduleSaved"));
        },
    },
};
</script>

<style scoped>
.schedule-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.day-schedule {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.day-header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: bold;
}

.add-btn {
    background: none;
    border: none;
    color: green;
    font-size: 18px;
    cursor: pointer;
}

.delete-btn {
    background: none;
    border: none;
    color: red;
    font-size: 18px;
    cursor: pointer;
}
</style>
